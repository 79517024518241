'use client'

import { createTheme } from '@mui/material/styles'

// Types
import type { Direction } from '@mui/material/styles'

// Fonts
import IranSansFont from '@/styles/fonts/iran-sans'
import OpenSansFont from '@/styles/fonts/open-sans'

const ThemeConfig = (dir: Direction) =>
	createTheme({
		direction: dir,
		cssVariables: { colorSchemeSelector: 'class' },
		colorSchemes: {
			light: {
				palette: {
					mode: 'light',
					primary: { light: '#85ffff', main: '#00c2c2', dark: '#008888', contrastText: '#141414' },
					secondary: { light: '#ffdada', main: '#ff8484', dark: '#ff5e5e', contrastText: '#141414' },
					background: { default: '#fcfcfd', paper: '#fff' }
				}
			},
			dark: {
				palette: {
					mode: 'dark',
					primary: { light: '#85ffff', main: '#00c2c2', dark: '#008888', contrastText: '#141414' },
					secondary: { light: '#ffdada', main: '#ff8484', dark: '#ff5e5e', contrastText: '#141414' },
					background: { default: '#151718', paper: '#212121' }
				}
			}
		},
		shape: { borderRadius: 8 },
		typography: {
			fontFamily: dir === 'rtl' ? IranSansFont.style.fontFamily : OpenSansFont.style.fontFamily,
			fontSize: 14
		},
		components: {
			MuiCssBaseline: {
				styleOverrides: {
					body: { scrollBehavior: 'smooth' },
					a: { textDecoration: 'none' }
				}
			}
		}
	})

export default ThemeConfig
