'use client'

import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

// Types
import type { LocalizationProviderProps } from '@/types/components/providers/localization'

const LocalizationProvider = (props: LocalizationProviderProps) => {
	// Props
	const { children } = props

	return <MuiLocalizationProvider dateAdapter={AdapterMoment}>{children}</MuiLocalizationProvider>
}

export default LocalizationProvider
