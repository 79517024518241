'use client'

import { useState, useEffect } from 'react'
import { usePathname } from 'next/navigation'

// Types
import type { TitleProviderProps } from '@/types/components/providers/title'

// Helpers
import TitleContext from '@/helpers/context/title'

const TitleProvider = (props: TitleProviderProps) => {
	// Props
	const { children } = props

	// Variables
	const pathname = usePathname()
	const [title, setTitle] = useState<string>('')

	// Callbacks
	useEffect(() => {
		if (title !== document.title) setTitle(document.title)
	}, [title, pathname])

	return <TitleContext.Provider value={{ title, setTitle }}>{children}</TitleContext.Provider>
}

export default TitleProvider
